import React from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO"

export default () => {
  return (
    <Layout>
      <Seo />
      <h1>Registry</h1>
      <p>After a whole year of being at a stand-still, we're finally heading into a whirlwind of change.  In addition to getting married, we'll be making our first moves into home-ownership in Honeoye Falls this spring.  While we have a lot of the basic homemaking staples covered at this stage in our relationship, there are some bigger-ticket, home-owner-type purchases that we'll need to make pretty soon.  Any monetary contributions you'd like to make will be going towards things like:</p>
      <ul>
        <li>A lawnmower for that big ol' backyard.</li>
        <li>A snowblower for to clear our new driveway and sidewalk.</li>
        <li>Paint! This new house is gonna get painted up real good!</li>
        <li>Renovations!</li>
        <ul>
          <li>We have a whole bathroom to put in.  That means we'll need to buy a bathtub, sink, mirrors... all the classics!  You can even use it when you come to visit us.  Now that's what I call putting your money to work for you!</li>
          <li>Great news - we have a garage now!  Bad news: it's got a few leaks (and some other maladies) that we'd like to patch up.</li>
          <li>While the interior of our new home is pretty nice, the exterior has a little ways to go.  We're talkin' new trim, a fresh coat of paint, and getting that clapboard out from underneath that weird siding.  It's going to be a real nerd-takes-of-their-glasses-and-it-turns-out-they-were-actually-really-attractive-the-whole-time situation!</li>
        </ul>
      </ul>
      <p>Finally, thank you for making yourselves available to come to our wedding.  We know that there are built-in monetary and scheduling costs to showing up to something like this.  Please know that we appreciate it and that you’re presence is more than enough of a gift.</p>
      <p>For your convenience, feel free to submit any contributions using the button below.  And again, <i>thank you!</i></p>
    <a className="button" href="https://paypal.me/atomandhannah?locale.x=en_US">Help Us Buy Stuff</a>
    </Layout>
  )
}

